<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            <template v-if="formModeView===false">
              <template v-if="sw372s01!==null">
                {{ $t('Editar qualificação') }}
              </template>
              <template v-else>
                {{ $t('Nova qualificação') }}
              </template>
            </template>
            <template v-else>
              {{ $t('Qualificação') }}
            </template>
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <validation-observer
          #default="{ }"
          ref="formSaveValidatorSW372"
        >
          <b-form
            @submit.prevent
          >
            <vue-perfect-scrollbar
              class="b-sidebar-scrollarea-form"
              :settings="configSettingsScroll"
            >
              <b-container
                class="pt-2 pb-0 pl-2 pr-2"
                fluid
              >
                <!-- Data Qualificacao -->
                <b-row v-if="dataQualificacao">
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Data qualificação')"
                    >
                      <p class="text-primary mb-0">
                        {{ dataQualificacao }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Imovel -->
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Tipo')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw372s04"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.tipos"
                        append-to-body
                        :calculate-position="withPopper"
                        label="sw087s02"
                        item-text="sw087s02"
                        item-value="sw087s01"
                        @input="getAllListingsByType"
                        @change="getAllListingsByType"
                      >
                        <template #option="{ sw087s02 }">
                          {{ sw087s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s04 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Imóvel')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw026s03"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.imoveis"
                        append-to-body
                        :calculate-position="withPopper"
                        :loading="loader.imovel"
                        :multiple="true"
                        label="desc"
                        item-text="desc"
                        item-value="id"
                        @input="getAllSubTypesListings"
                        @change="getAllSubTypesListings"
                      >
                        <template #option="{ desc }">
                          {{ desc }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw026s03 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="showListingsWithTypology===true">
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Tipologia')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw042s03"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.tipologias"
                        append-to-body
                        :calculate-position="withPopper"
                        :multiple="true"
                        label="sw005s02"
                        item-text="sw005s02"
                        item-value="sw005s01"
                      >
                        <template #option="{ sw005s02 }">
                          {{ sw005s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw042s03 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-show="form.tipologiasEspecial.length > 0">
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Especial')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw230s03"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.tipologiasEspecial"
                        append-to-body
                        :calculate-position="withPopperGroup"
                        :multiple="true"
                        :loading="loader.especial"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                        :selectable="option => option.group === null"
                      >
                        <template #option="{ group, txt }">
                          <div
                            v-if="group"
                            class="group"
                          >
                            {{ group }}
                          </div>
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw230s03 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Condição do imóvel')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw372s05"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.condicaoImovel"
                        append-to-body
                        :calculate-position="withPopper"
                        :multiple="true"
                        label="sw010s02"
                        item-text="sw010s02"
                        item-value="sw010s01"
                      >
                        <template #option="{ sw010s02 }">
                          {{ sw010s02 }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s05 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Bloco localizacao -->
                <b-row>
                  <b-col md="12">
                    <component
                      :is="blockLocalizationSW372"
                      ref="locationSW372"
                      :view="formModeView"
                    />
                  </b-col>
                </b-row>

                <!-- Outros -->
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Procura comprar há quanto tempo?')"
                    >
                      <b-input-group
                        v-if="formModeView===false"
                        class="input-group-merge input-with-select"
                      >
                        <b-form-input
                          v-model="sw372s06"
                          v-mask="['#####']"
                          placeholder=""
                          autocomplete="off"
                          inputmode="numeric"
                        />
                        <b-input-group-append
                          is-text
                        >
                          <v-select
                            v-model="sw372s07"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="form.periodos"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            :placeholder="$t('Escolha uma opção')"
                            label="txt"
                            item-text="txt"
                            item-value="id"
                            style="width:150px;"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-input-group-append>
                      </b-input-group>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s06_sw372s07 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Pretende comprar dentro de quanto tempo?')"
                    >
                      <b-input-group
                        v-if="formModeView===false"
                        class="input-group-merge input-with-select"
                      >
                        <b-form-input
                          v-model="sw372s08"
                          v-mask="['#####']"
                          placeholder=""
                          autocomplete="off"
                          inputmode="numeric"
                        />
                        <b-input-group-append
                          is-text
                        >
                          <v-select
                            v-model="sw372s09"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="form.periodos"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            :placeholder="$t('Escolha uma opção')"
                            label="txt"
                            item-text="txt"
                            item-value="id"
                            style="width:150px;"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-input-group-append>
                      </b-input-group>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s08_sw372s09 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Motivo de compra')"
                    >
                      <b-form-input
                        v-if="formModeView===false"
                        v-model="sw372s10"
                        placeholder=""
                        autocomplete="off"
                      />
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s10 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Intervalo de valor disposto a investir')"
                    >
                      <b-row
                        v-if="formModeView===false"
                      >
                        <b-col md="5">
                          <b-input-group
                            :prepend="symbolMoney"
                            append=""
                            class="input-group-merge"
                          >
                            <b-form-input
                              v-model="sw372s11"
                              v-currency="configMoney"
                              placeholder=""
                              autocomplete="off"
                            />
                          </b-input-group>
                        </b-col>
                        <b-col
                          cols="12"
                          sm="12"
                          md="2"
                          class="d-flex align-items-center justify-content-center"
                        >
                          <div class="mt-1 mb-1 mt-sm-0 mb-sm-0">
                            {{ $t('até') }}
                          </div>

                        </b-col>
                        <b-col md="5">
                          <b-input-group
                            :prepend="symbolMoney"
                            append=""
                            class="input-group-merge"
                          >
                            <b-form-input
                              v-model="sw372s12"
                              v-currency="configMoney"
                              placeholder=""
                              autocomplete="off"
                            />
                          </b-input-group>
                        </b-col>
                      </b-row>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s11_sw372s12 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Vai recorrer a financiamento?')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw372s13"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.recorrerFinanciamento"
                        append-to-body
                        :calculate-position="withPopper"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                        @input="sw372s41=null"
                      >
                        <template #option="{ txt }">
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s13 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="showRecorrerFinanciamento">
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Valor')"
                    >
                      <b-input-group
                        v-if="formModeView===false"
                        :prepend="symbolMoney"
                        append=""
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="sw372s41"
                          v-currency="configMoney"
                          placeholder=""
                          autocomplete="off"
                        />
                      </b-input-group>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ formatPrice(viewData.sw372s41) }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Observações face à oportunidade de compra')"
                    >
                      <b-form-textarea
                        v-if="formModeView===false"
                        v-model="sw372s14"
                        rows="3"
                        no-resize
                      />
                      <!-- eslint-disable vue/no-v-html -->
                      <p
                        v-else
                        class="text-primary mb-0"
                        v-html="viewData.sw372s14"
                      />
                      <!--eslint-enable-->
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Atributos -->
                <b-row>
                  <b-col md="12">
                    <h4 class="pt-1 pb-1">
                      {{ $t('Atributos') }}
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Nº de suites')"
                    >
                      <b-form-input
                        v-if="formModeView===false"
                        v-model="sw372s15"
                        v-mask="['#####']"
                        placeholder=""
                        autocomplete="off"
                        inputmode="numeric"
                      />
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s15 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Área do terreno')"
                    >
                      <b-input-group
                        v-if="formModeView===false"
                        class="input-group-merge"
                      >
                        <b-form-input
                          v-model="sw372s16"
                          v-mask="['##########']"
                          placeholder=""
                          autocomplete="off"
                          inputmode="numeric"
                        />
                        <b-input-group-append is-text>
                          {{ $t('m2') }}
                        </b-input-group-append>
                      </b-input-group>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s16 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Obras no imóvel')"
                    >
                      <v-select
                        v-if="formModeView===false"
                        v-model="sw372s17"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="form.obrasImovel"
                        append-to-body
                        :calculate-position="withPopper"
                        :multiple="true"
                        label="txt"
                        item-text="txt"
                        item-value="id"
                      >
                        <template #option="{ txt }">
                          {{ txt }}
                        </template>
                        <div slot="no-options">
                          {{ $t('Sem resultados') }}
                        </div>
                      </v-select>
                      <p
                        v-else
                        class="text-primary mb-0"
                      >
                        {{ viewData.sw372s17 }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-if="formModeView===false"
                >
                  <b-col md="12">
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s18"
                            value="1"
                            @change="sw372s19=null"
                          >
                            {{ $t('Garagem') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="sw372s18==='1'">
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Nº lugares')"
                        >
                          <b-form-input
                            v-model="sw372s19"
                            v-mask="['#####']"
                            placeholder=""
                            autocomplete="off"
                            inputmode="numeric"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s20"
                            value="1"
                          >
                            {{ $t('Parqueamento') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s21"
                            value="1"
                          >
                            {{ $t('Quintal') }} / {{ $t('Jardim') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s22"
                            value="1"
                          >
                            {{ $t('Terraço') }}  / {{ $t('Varanda') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s23"
                            value="1"
                          >
                            {{ $t('Piscina') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s24"
                            value="1"
                          >
                            {{ $t('Vista mar') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s25"
                            value="1"
                          >
                            {{ $t('Vista rio') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s26"
                            value="1"
                          >
                            {{ $t('Vista cidade') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s27"
                            value="1"
                          >
                            {{ $t('Vista campo') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s28"
                            value="1"
                          >
                            {{ $t('Condomínio fechado') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s29"
                            value="1"
                          >
                            {{ $t('Imóvel de banco') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s30"
                            value="1"
                          >
                            {{ $t('Elevador') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s31"
                            value="1"
                          >
                            {{ $t('Ar condicionado') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s32"
                            value="1"
                          >
                            {{ $t('Aquecimento central') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s33"
                            value="1"
                          >
                            {{ $t('Lareira') }} /  {{ $t('Recuperador de calor') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s34"
                            value="1"
                          >
                            {{ $t('Boa exposição solar') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s35"
                            value="1"
                          >
                            {{ $t('Painéis solares') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s36"
                            value="1"
                          >
                            {{ $t('Poço') }} /  {{ $t('Furo de água') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s37"
                            value="1"
                          >
                            {{ $t('Privacidade') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s38"
                            value="1"
                          >
                            {{ $t('Distância à cidade') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s39"
                            value="1"
                          >
                            {{ $t('Rega automática') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            v-model="sw372s40"
                            value="1"
                          >
                            {{ $t('Sistema de aquecimento') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
                <b-row
                  v-else
                >
                  <b-col md="12">
                    <b-form-group
                      :label="$t('Outros atributos')"
                    >
                      <p
                        class="text-primary mb-0"
                      >
                        {{ viewData.txtListAttr }}
                      </p>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-container>
            </vue-perfect-scrollbar>
            <div
              v-if="formModeView===false"
              class="d-flex p-1 justify-content-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                @click.stop.prevent="saveFormSW372"
              >
                {{ $t('Guardar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click.stop.prevent="clearFormSW372"
              >
                {{ $t('Limpar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click.stop.prevent="hide"
              >
                {{ $t('Cancelar') }}
              </b-button>
            </div>
            <div
              v-if="formModeView===true"
              class="d-flex p-1 justify-content-center"
            >
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click.stop.prevent="hide"
              >
                {{ $t('Fechar') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BFormGroup, BButton, BRow, BCol, BForm, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BFormTextarea, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'
import modelSW012 from '@store-modules/listings/data'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import { formFields, formFieldsExtra } from './index'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
    ValidationObserver,
    VuePerfectScrollbar,
    BContainer,
  },
  directives: {
    Ripple,
    mask,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      configMoney,
      symbolMoney,
      loader: {
        imovel: false,
        especial: false,
      },
      formFields,
      ...formFields,
      ...formFieldsExtra,
      formatPrice,
    }
  },
  computed:
  {
    ...mapGetters('sw372', ['form', 'formModeView', 'loadingFormCreateNew']),
    showRecorrerFinanciamento() {
      try {
        if (this.sw372s13 !== null) {
          if ('id' in this.sw372s13) {
            if (this.sw372s13.id === 1) {
              return true
            }
          }
        }
      } catch (err) {
        //
      }

      return false
    },
    viewData() {
      if (this.loadingFormCreateNew === true) {
        return {}
      }

      const { t } = useI18nUtils()
      const txtDefault = '-----'

      try {
        let txtSW372s04 = ''
        if ((this.sw372s04 !== null) && ('sw087s02' in this.sw372s04)) {
          txtSW372s04 = this.sw372s04.sw087s02
        }

        let txtSW026s03 = ''
        if ((this.sw026s03 !== null) && (this.sw026s03.length > 0)) {
          this.sw026s03.forEach(row => {
            txtSW026s03 += ((txtSW026s03) ? ', ' : '')
            txtSW026s03 += row.desc
          })
        }

        let txtSW042s03 = ''
        if ((this.sw042s03 !== null) && (this.sw042s03.length > 0)) {
          this.sw042s03.forEach(row => {
            txtSW042s03 += ((txtSW042s03) ? ', ' : '')
            txtSW042s03 += row.sw005s02
          })
        }

        let txtSW230s03 = ''
        if ((this.sw230s03 !== null) && (this.sw230s03.length > 0)) {
          this.sw230s03.forEach(row => {
            txtSW230s03 += ((txtSW230s03) ? ', ' : '')
            txtSW230s03 += row.txt
          })
        }

        let txtSW372s05 = ''
        if ((this.sw372s05 !== null) && (this.sw372s05.length > 0)) {
          this.sw372s05.forEach(row => {
            txtSW372s05 += ((txtSW372s05) ? ', ' : '')
            txtSW372s05 += row.sw010s02
          })
        }

        let txtSW372s06andSW372s07 = ''
        if (this.sw372s06) {
          txtSW372s06andSW372s07 += this.sw372s06

          if ((this.sw372s07 !== null) && ('txt' in this.sw372s07)) {
            txtSW372s06andSW372s07 += ` ${this.sw372s07.txt}`
          }
        }

        let txtSW372s08andSW372s09 = ''
        if (this.sw372s08) {
          txtSW372s08andSW372s09 += this.sw372s08

          if ((this.sw372s09 !== null) && ('txt' in this.sw372s09)) {
            txtSW372s08andSW372s09 += ` ${this.sw372s09.txt}`
          }
        }

        let txtSW372s11andSW372s12 = ''
        if (this.sw372s11) {
          const txt = t('De')
          txtSW372s11andSW372s12 += `${txt} `
          txtSW372s11andSW372s12 += `${this.formatPrice(this.sw372s11)}${symbolMoney}`
        }
        if (this.sw372s12) {
          if (txtSW372s11andSW372s12 !== '') {
            const txt = t('até')
            txtSW372s11andSW372s12 += ` ${txt} `
          } else {
            const txt = t('Até')
            txtSW372s11andSW372s12 += `${txt} `
          }

          txtSW372s11andSW372s12 += `${this.formatPrice(this.sw372s12)}${symbolMoney}`
        }

        let txtSW372s13 = ''
        if ((this.sw372s13 !== null) && ('txt' in this.sw372s13)) {
          txtSW372s13 += this.sw372s13.txt
        }

        let txtSW372s41 = ''
        if (this.sw372s41) {
          txtSW372s41 += `${this.formatPrice(this.sw372s41)}${symbolMoney}`
        }

        let txtSW372s16 = ''
        if (this.sw372s16) {
          txtSW372s16 += `${this.sw372s16}${t('m2')}`
        }

        let txtSW372s17 = ''
        if ((this.sw372s17 !== null) && (this.sw372s17.length > 0)) {
          this.sw372s17.forEach(row => {
            txtSW372s17 += ((txtSW372s17) ? ', ' : '')
            txtSW372s17 += row.txt
          })
        }

        let txtListAttr = ''

        if (this.sw372s18) {
          if (this.sw372s19 > 1) {
            const txtAttr = t('Garagens')
            txtListAttr += `${this.sw372s19} ${txtAttr}`
          } else {
            const txtAttr = t('Garagem')
            txtListAttr += `${this.sw372s19} ${txtAttr}`
          }
        }

        if (this.sw372s20) {
          const txtAttr = t('Parqueamento')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s21) {
          const txtAttr1 = t('Quintal')
          const txtAttr2 = t('Jardim')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr1} / ${txtAttr2}`
        }

        if (this.sw372s22) {
          const txtAttr1 = t('Terraço')
          const txtAttr2 = t('Varanda')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr1} / ${txtAttr2}`
        }

        if (this.sw372s23) {
          const txtAttr = t('Piscina')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s24) {
          const txtAttr = t('Vista mar')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s25) {
          const txtAttr = t('Vista rio')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s26) {
          const txtAttr = t('Vista cidade')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s27) {
          const txtAttr = t('Vista campo')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s28) {
          const txtAttr = t('Condomínio fechado')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s29) {
          const txtAttr = t('Imóvel de banco')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s30) {
          const txtAttr = t('Elevador')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s31) {
          const txtAttr = t('Ar condicionado')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s32) {
          const txtAttr = t('Aquecimento central')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s33) {
          const txtAttr1 = t('Lareira')
          const txtAttr2 = t('Recuperador de calor')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr1} / ${txtAttr2}`
        }

        if (this.sw372s34) {
          const txtAttr = t('Boa exposição solar')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s35) {
          const txtAttr = t('Painéis solares')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s36) {
          const txtAttr1 = t('Poço')
          const txtAttr2 = t('Furo de água')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr1} / ${txtAttr2}`
        }

        if (this.sw372s37) {
          const txtAttr = t('Privacidade')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s38) {
          const txtAttr = t('Distância à cidade')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s39) {
          const txtAttr = t('Rega automática')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        if (this.sw372s40) {
          const txtAttr = t('Sistema de aquecimento')
          txtListAttr += ((txtListAttr) ? ', ' : '')
          txtListAttr += `${txtAttr}`
        }

        return {
          sw372s04: txtSW372s04 || txtDefault,
          sw026s03: txtSW026s03 || txtDefault,
          sw042s03: txtSW042s03 || txtDefault,
          sw230s03: txtSW230s03 || txtDefault,
          sw372s05: txtSW372s05 || txtDefault,
          sw372s06_sw372s07: txtSW372s06andSW372s07 || txtDefault,
          sw372s08_sw372s09: txtSW372s08andSW372s09 || txtDefault,
          sw372s10: this.sw372s10 || txtDefault,
          sw372s11_sw372s12: txtSW372s11andSW372s12 || txtDefault,
          sw372s13: txtSW372s13 || txtDefault,
          sw372s41: txtSW372s41 || txtDefault,
          sw372s14: this.$options.filters.nl2br(this.sw372s14) || txtDefault,
          sw372s15: this.sw372s15 || txtDefault,
          sw372s16: txtSW372s16 || txtDefault,
          sw372s17: txtSW372s17 || txtDefault,
          txtListAttr: txtListAttr || txtDefault,
        }
      } catch (err) {
        return {
          sw372s04: txtDefault,
          sw026s03: txtDefault,
          sw042s03: txtDefault,
          sw230s03: txtDefault,
          sw372s05: txtDefault,
          sw372s06_sw372s07: txtDefault,
          sw372s08_sw372s09: txtDefault,
          sw372s10: txtDefault,
          sw372s11_sw372s12: txtDefault,
          sw372s13: txtDefault,
          sw372s41: txtDefault,
          sw372s14: txtDefault,
          sw372s15: txtDefault,
          sw372s16: txtDefault,
          sw372s17: txtDefault,
          txtListAttr: txtDefault,
        }
      }
    },
  },
  async created() {
    try {
      const countryApp = this.$store.getters['auth/countryApp']
      this.blockLocalizationSW372 = () => import(`@/views/modules/components/localization/country/form_multiple/${countryApp}.vue`)

      this.$store.dispatch('sw372/modalNewQualificacao').then(() => {
        this.fieldsLoadDataDefault()
        this.loadDataForm()
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.closeModalForm()
      })
    } catch (err) {
      //
    }
  },
  methods: {
    closeModalForm(val) {
      this.$store.dispatch('request/cancelTokensPendingByUrl', 'leads/modalNewQualificacao', { root: true })
      this.$store.dispatch('sw372/setShowFormCreateNew', val).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async saveFormSW372() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSW372.validate()
          .then(async valid => {
            if (valid) {
              let nFieldFill = 0
              const oData = {}
              Object.keys(this.formFields).forEach(field => {
                oData[field] = this[field]

                if ((this[field] !== null) && (!['sw372s07', 'sw372s09'].includes(field))) {
                  if (Array.isArray(this[field])) {
                    if (this[field].length > 0) {
                      nFieldFill += 1
                    }
                  } else {
                    nFieldFill += 1
                  }
                }
              })

              // Obter campos da localizacao
              await this.$refs.locationSW372.getDataSaveForm().then(res => {
                oData.sw043s10 = res.level1
                oData.sw043s03 = res.level2
                oData.sw043s04 = res.level3
                oData.sw043s07 = res.level4
                oData.sw043s08 = res.level5

                if ((res.level1) && (Array.isArray(res.level1)) && (res.level1.length > 0)) {
                  nFieldFill += 1
                }
                if ((res.level2) && (Array.isArray(res.level2)) && (res.level2.length > 0)) {
                  nFieldFill += 1
                }
                if ((res.level3) && (Array.isArray(res.level3)) && (res.level3.length > 0)) {
                  nFieldFill += 1
                }
                if ((res.level4) && (Array.isArray(res.level4)) && (res.level4.length > 0)) {
                  nFieldFill += 1
                }
                if ((res.level5) && (Array.isArray(res.level5)) && (res.level5.length > 0)) {
                  nFieldFill += 1
                }
              })

              if (nFieldFill > 0) {
                self.$root.$emit('app::loading', true)
                this.$store.dispatch('sw372/saveSW372', oData).then(res => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgSuccessRequest(res)
                  self.closeModalForm()
                  self.$parent.init()
                }).catch(error => {
                  self.$root.$emit('app::loading', false)
                  self.showMsgErrorRequest(error)
                })
              } else {
                this.showMsgErrorRequest(new Error(this.$t('Tem de ter pelo menos um campo preenchido')))
              }
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async clearFormSW372() {
      try {
        this.fieldsLoadDataDefault()
        this.$refs.locationSW372.clearForm()
        this.$refs.formSaveValidatorSW372.reset()
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao limpar formulário') })
      }
    },
    async loadDataForm() {
      if (typeof this.data.sw372 === 'object') {
        if (this.data.sw372.sw372s01) {
          this.sw372s01 = this.data.sw372.sw372s01
        }

        if (('dataQualificacao' in this.data) && (this.data.dataQualificacao)) {
          this.dataQualificacao = this.data.dataQualificacao
        }

        if (this.data.sw372.sw372s04) {
          this.sw372s04 = this.form.tipos.find(o => Number(o.sw087s01) === Number(this.data.sw372.sw372s04))
          await this.getAllListingsByType(this.sw372s04)
        }

        if (Array.isArray(this.data.sw026s03)) {
          this.data.sw026s03.forEach(val => {
            const newItem = this.form.imoveis.find(o => Number(o.id) === Number(val))
            if (newItem !== undefined) {
              this.sw026s03.push(newItem)
            }
          })
          await this.getAllSubTypesListings(this.sw026s03)
        }

        if (Array.isArray(this.data.sw042s03)) {
          this.data.sw042s03.forEach(val => {
            const newItem = this.form.tipologias.find(o => Number(o.sw005s01) === Number(val))
            if (newItem !== undefined) {
              this.sw042s03.push(newItem)
            }
          })
        }

        if (Array.isArray(this.data.sw230s03)) {
          this.data.sw230s03.forEach(val => {
            const newItem = this.form.tipologiasEspecial.find(o => Number(o.id) === Number(val))
            if (newItem !== undefined) {
              this.sw230s03.push(newItem)
            }
          })
        }

        if (this.data.sw372.sw372s05) {
          const aSW372s05 = this.data.sw372.sw372s05.split(';')
          if (Array.isArray(aSW372s05)) {
            aSW372s05.forEach(val => {
              const newItem = this.form.condicaoImovel.find(o => Number(o.sw010s01) === Number(val))
              if (newItem !== undefined) {
                this.sw372s05.push(newItem)
              }
            })
          }
        }

        if (this.data.sw372.sw372s06) {
          this.sw372s06 = this.data.sw372.sw372s06
        }

        if (this.data.sw372.sw372s07) {
          this.sw372s07 = this.form.periodos.find(o => Number(o.id) === Number(this.data.sw372.sw372s07))
        }

        if (this.data.sw372.sw372s08) {
          this.sw372s08 = this.data.sw372.sw372s08
        }

        if (this.data.sw372.sw372s09) {
          this.sw372s09 = this.form.periodos.find(o => Number(o.id) === Number(this.data.sw372.sw372s09))
        }

        if (this.data.sw372.sw372s10) {
          this.sw372s10 = this.data.sw372.sw372s10
        }

        if (this.data.sw372.sw372s11) {
          this.sw372s11 = this.data.sw372.sw372s11
        }

        if (this.data.sw372.sw372s12) {
          this.sw372s12 = this.data.sw372.sw372s12
        }

        if (this.data.sw372.sw372s13) {
          this.sw372s13 = this.form.recorrerFinanciamento.find(o => Number(o.id) === Number(this.data.sw372.sw372s13))
        }

        if (this.data.sw372.sw372s41) {
          this.sw372s41 = this.data.sw372.sw372s41
        }

        if (this.data.sw372.sw372s14) {
          this.sw372s14 = this.data.sw372.sw372s14
        }

        if (this.data.sw372.sw372s15) {
          this.sw372s15 = this.data.sw372.sw372s15
        }

        if (this.data.sw372.sw372s16) {
          this.sw372s16 = this.data.sw372.sw372s16
        }

        if (this.data.sw372.sw372s17) {
          const aSW372s17 = this.data.sw372.sw372s17.split(';')
          if (Array.isArray(aSW372s17)) {
            aSW372s17.forEach(val => {
              const newItem = this.form.obrasImovel.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.sw372s17.push(newItem)
              }
            })
          }
        }

        if (this.data.sw372.sw372s18) {
          this.sw372s18 = this.data.sw372.sw372s18
        }

        if (this.data.sw372.sw372s19) {
          this.sw372s19 = this.data.sw372.sw372s19
        }

        if (this.data.sw372.sw372s20) {
          this.sw372s20 = this.data.sw372.sw372s20
        }

        if (this.data.sw372.sw372s21) {
          this.sw372s21 = this.data.sw372.sw372s21
        }

        if (this.data.sw372.sw372s22) {
          this.sw372s22 = this.data.sw372.sw372s22
        }

        if (this.data.sw372.sw372s23) {
          this.sw372s23 = this.data.sw372.sw372s23
        }

        if (this.data.sw372.sw372s24) {
          this.sw372s24 = this.data.sw372.sw372s24
        }

        if (this.data.sw372.sw372s25) {
          this.sw372s25 = this.data.sw372.sw372s25
        }

        if (this.data.sw372.sw372s26) {
          this.sw372s26 = this.data.sw372.sw372s26
        }

        if (this.data.sw372.sw372s27) {
          this.sw372s27 = this.data.sw372.sw372s27
        }

        if (this.data.sw372.sw372s28) {
          this.sw372s28 = this.data.sw372.sw372s28
        }

        if (this.data.sw372.sw372s29) {
          this.sw372s29 = this.data.sw372.sw372s29
        }

        if (this.data.sw372.sw372s30) {
          this.sw372s30 = this.data.sw372.sw372s30
        }

        if (this.data.sw372.sw372s31) {
          this.sw372s31 = this.data.sw372.sw372s31
        }

        if (this.data.sw372.sw372s32) {
          this.sw372s32 = this.data.sw372.sw372s32
        }

        if (this.data.sw372.sw372s33) {
          this.sw372s33 = this.data.sw372.sw372s33
        }

        if (this.data.sw372.sw372s34) {
          this.sw372s34 = this.data.sw372.sw372s34
        }

        if (this.data.sw372.sw372s35) {
          this.sw372s35 = this.data.sw372.sw372s35
        }

        if (this.data.sw372.sw372s36) {
          this.sw372s36 = this.data.sw372.sw372s36
        }

        if (this.data.sw372.sw372s37) {
          this.sw372s37 = this.data.sw372.sw372s37
        }

        if (this.data.sw372.sw372s38) {
          this.sw372s38 = this.data.sw372.sw372s38
        }

        if (this.data.sw372.sw372s39) {
          this.sw372s39 = this.data.sw372.sw372s39
        }

        if (this.data.sw372.sw372s40) {
          this.sw372s40 = this.data.sw372.sw372s40
        }

        // Obters IDs da localizacao para enviar para o componente
        const dataLevels = {
          level1: [],
          level2: [],
          level3: [],
          level4: [],
          level5: [],
        }

        if (('sw043s10' in this.data) && (this.data.sw043s10.length > 0)) {
          this.data.sw043s10.forEach(val => {
            dataLevels.level1.push(val)
          })
        }

        if (('sw043s03' in this.data) && (this.data.sw043s03.length > 0)) {
          this.data.sw043s03.forEach(val => {
            dataLevels.level2.push(val)
          })
        }

        if (('sw043s04' in this.data) && (this.data.sw043s04.length > 0)) {
          this.data.sw043s04.forEach(val => {
            dataLevels.level3.push(val)
          })
        }

        if (('sw043s07' in this.data) && (this.data.sw043s07.length > 0)) {
          this.data.sw043s07.forEach(val => {
            dataLevels.level4.push(val)
          })
        }

        if (('sw043s08' in this.data) && (this.data.sw043s08.length > 0)) {
          this.data.sw043s08.forEach(val => {
            dataLevels.level5.push(val)
          })
        }

        await this.$refs.locationSW372.loadDataForm(dataLevels)

        this.$store.dispatch('sw372/setLoadingDataSW372', false)
        this.$root.$emit('app::loading', false)
      }
    },
    async fieldsLoadDataDefault() {
      Object.keys(this.formFields).forEach(field => {
        if (Array.isArray(this.formFields[field])) {
          this[field] = []
        } else {
          this[field] = this.formFields[field]
        }
      })

      this.sw372s07 = this.form.periodos.find(o => o.id === 2)
      this.sw372s09 = this.form.periodos.find(o => o.id === 2)

      this.form.imoveis = []
      this.form.tipologiasEspecial = []
      this.showListingsWithTypology = false
    },
    async getAllListingsByType(row) {
      if (row !== null) {
        if (('sw087s03' in row) && (row.sw087s03)) {
          this.loader.imovel = true
          await this.$store.dispatch('sw012/getAllListingsByType', row.sw087s03).then(res => {
            this.loader.imovel = false
            this.$store.commit('sw372/setFormImoveis', res)
            this.resetFieldsGroupListing()
          }).catch(error => {
            this.loader.imovel = false
            this.$store.commit('sw372/setFormImoveis', [])
            this.resetFieldsGroupListing()
            this.showMsgErrorRequest(error)
          })
        } else {
          this.$store.commit('sw372/setFormImoveis', [])
          this.resetFieldsGroupListing()
        }
      } else {
        this.$store.commit('sw372/setFormImoveis', [])
        this.resetFieldsGroupListing()
      }
    },
    async getAllSubTypesListings(rows) {
      let showFieldTypology = 0
      const idsSubTypesListings = []

      if (rows.length > 0) {
        rows.forEach(o => {
          if (this.$store.getters['auth/countryApp'].includes('pt')) {
            if (('show_cl_tipologia' in o) && (o.show_cl_tipologia)) {
              showFieldTypology += 1
            }
          }

          if (this.$store.getters['auth/countryApp'].includes('es')) {
            if (('show_field_atrib_rooms' in o) && (o.show_field_atrib_rooms)) {
              showFieldTypology += 1
            }
          }

          if (('sub' in o) && (Object.keys(o.sub).length > 0) && ('escolha_especial' in o) && (o.escolha_especial)) {
            idsSubTypesListings.push(o.id)
          }
        })
      }

      if (showFieldTypology > 0) {
        this.showListingsWithTypology = true
      } else {
        this.showListingsWithTypology = false
        this.sw042s03 = []
      }

      if (idsSubTypesListings.length > 0) {
        this.sw230s03 = []
        this.loader.especial = true
        await this.$store.dispatch('sw012/getAllSubTypesListings', idsSubTypesListings).then(res => {
          this.loader.especial = false
          this.$store.commit('sw372/setFormTipologiasEspecial', res)
        }).catch(error => {
          this.loader.especial = false
          this.$store.commit('sw372/setFormTipologiasEspecial', [])
          this.showMsgErrorRequest(error)
        })
      } else {
        this.sw230s03 = []
        this.$store.commit('sw372/setFormTipologiasEspecial', [])
      }
    },
    async resetFieldsGroupListing() {
      this.sw026s03 = []
      this.sw042s03 = []
      this.sw230s03 = []
      this.showListingsWithTypology = false
    },
  },
  setup() {
    const LISTINGS_MODULE_NAME = 'sw012'

    if (!store.hasModule(LISTINGS_MODULE_NAME)) {
      store.registerModule(LISTINGS_MODULE_NAME, modelSW012)

      onUnmounted(() => {
        if (store.hasModule(LISTINGS_MODULE_NAME)) store.unregisterModule(LISTINGS_MODULE_NAME)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>
