import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {},
  getters: {
  },
  mutations: {
  },
  actions: {

    async getAllListingsByType(state, payload) {
      const formData = new FormData()
      formData.append('sw004s08', payload)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getComboTipoImoveisByCategoria`, formData)
          .then(response => {
            if (typeof response.data.imoveis === 'object') {
              resolve(response.data.imoveis)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os imóveis')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os imóveis')))
            }
          })
      })
    },

    async getAllSubTypesListings(state, payload) {
      const formData = new FormData()

      if (Array.isArray(payload)) {
        payload.forEach(o => {
          formData.append('sw004s09[]', o)
        })
      }

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/getSubTipoImoveis`, formData)
          .then(response => {
            if (typeof response.data.tipos === 'object') {
              resolve(response.data.tipos)
            } else {
              reject(new Error(i18n.t('Problema ao carregar a tipologia especial do imóvel')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar a tipologia especial do imóvel')))
            }
          })
      })
    },

    async getModalListings() {
      const formData = new FormData()

      // console.log(payload)
      /*
      if (Array.isArray(payload)) {
        payload.forEach(o => {
          formData.append('sw004s09[]', o)
        })
      }
      */

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/loadModalListings`, formData)
          .then(response => {
            if (typeof response.data.tipos === 'object') {
              resolve(response.data.tipos)
            } else {
              reject(new Error(i18n.t('Problema ao carregar os imóveis')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os imóveis')))
            }
          })
      })
    },

  },
}
