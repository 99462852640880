export const formFields = {
  sw372s01: null,
  sw372s04: null,
  sw026s03: [],
  sw042s03: [],
  sw230s03: [],
  sw372s05: [],
  sw372s15: null,
  sw372s16: null,
  sw372s17: [],
  sw372s18: null,
  sw372s19: null,
  sw372s20: null,
  sw372s21: null,
  sw372s22: null,
  sw372s23: null,
  sw372s24: null,
  sw372s25: null,
  sw372s26: null,
  sw372s27: null,
  sw372s28: null,
  sw372s29: null,
  sw372s30: null,
  sw372s31: null,
  sw372s32: null,
  sw372s33: null,
  sw372s34: null,
  sw372s35: null,
  sw372s36: null,
  sw372s37: null,
  sw372s38: null,
  sw372s39: null,
  sw372s40: null,

  sw372s06: null,
  sw372s07: null,
  sw372s08: null,
  sw372s09: null,
  sw372s10: null,
  sw372s11: null,
  sw372s12: null,
  sw372s13: null,
  sw372s14: null,
  sw372s41: null,

  sw043s10: [],
  sw043s03: [],
  sw043s04: [],
  sw043s07: [],
  sw043s08: [],
}

export const formFieldsExtra = {
  showListingsWithTypology: false,
  dataQualificacao: null,
  blockLocalizationSW372: null,
}

export const _ = null
